import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import categoryimage from '../assests/images/category1.png'


const Products = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });


    const handleShowModal = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCategory('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            ...formData,
            category: selectedCategory, // Include selected category in the form submission
        };
        const baseUrl = process.env.REACT_APP_API_URI;
        try {
            const response = await axios.post(`${baseUrl}emails/send-category-email`, payload);
            console.log('Email sent successfully:', response.data);
            alert('Thank you! We will get back to you soon.');
            setFormData({ name: '', email: '', phone: '' }); // Clear form
            handleCloseModal(); // Close modal
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to submit the form. Please try again.');
        }
    };
    return (
        <>
            <div className="products">
                <div className="home-furnishing">
                    <Container fluid>
                        {/* Heading Section */}
                        <Row className="align-items-center">
                            <Col xs={12} md={12} className="pt-4 text-center">
                                <h1 className="text-darkbrown fs-2 ">Home Furnishing Products</h1>
                                {/* <p className="text-darkbrown fw-normal fs-5">
                                               Browse our curated collections for every style.
                                           </p> */}
                            </Col>
                        </Row>

                        {/* Categories Section */}
                        <Container>
                            <Row className="mt-4">
                                <Col xs={12} md={3} className="mb-4">
                                    <Link className="text-decoration-none" to="/category/home-comforts/curtains/premium-curtains">
                                        <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733380630/products/drejc8emjkhs7abmjj16.jpg" alt="Premium Curtains" className="img-fluid product-img" />
                                        <div>
                                            <h2 className="text-darkbrown fs-5 pt-3">Premium Curtains</h2>
                                        </div>
                                        <hr />
                                    </Link>
                                </Col>
                                <Col xs={12} md={3} className="mb-4">
                                    <Link className="text-decoration-none" onClick={() => handleShowModal('Standard Curtains')}>
                                        <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733380869/products/kbp7t9omziut5k8xmyzi.jpg" alt="Standard Curtains" className="img-fluid product-img" />
                                        <div>
                                            <h2 className="text-darkbrown fs-5 pt-3 ">Standard Curtains</h2>
                                        </div>
                                        <hr />
                                    </Link>
                                </Col>
                                {/* <Col xs={12} md={3} className="mb-4">
                                               <Link className="text-decoration-none" onClick={() => handleShowModal('Bedsheet')}>
                                                   <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733381481/products/kubzidtdikbfsn1ltydn.jpg" alt="Bedsheet" className="img-fluid product-img" />
                                                   <div>
                                                       <h5 className="text-darkbrown fs-5 pt-3">Bedsheet</h5>
                                                   </div>
                                                   <hr />
                                               </Link>
                                           </Col> */}
                                <Col xs={12} md={3} className="mb-4">
                                    <Link className='text-decoration-none' onClick={() => handleShowModal('Bed Matress')}>
                                        <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733516437/products/ye5dk0k5skbiq62zejgl.jpg" alt="" className="img-fluid product-img" />
                                        <div>
                                            <h2 className="text-darkbrown fs-5 pt-3">Bed Mattress</h2>
                                        </div>
                                        <hr />
                                    </Link>
                                </Col>
                                {/* <Col xs={12} md={3} className="mb-4">
                                               <Link className="text-decoration-none" onClick={() => handleShowModal('Sofa Cover')}>
                                                   <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732803418/products/khzpendrug25nkvmjdbm.jpg" alt="Sofa Cover" className="img-fluid product-img" />
                                                   <div>
                                                       <h5 className="text-darkbrown fs-5 pt-3 ">Sofa Cover</h5>
                                                   </div>
                                                   <hr />
                                               </Link>
                                           </Col> */}
                                <Col xs={12} md={3} className="mb-4">
                                    <Link className='text-decoration-none' onClick={() => handleShowModal('Bankets')}>
                                        <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733516540/products/v51lpg7k9juufqxnq1n6.jpg" alt="" className="img-fluid product-img" />
                                        <div>
                                            <h2 className="text-darkbrown fs-5 pt-3">Blankets</h2>
                                        </div>
                                        <hr />
                                    </Link>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col xs={12} md={3} className="mb-4">
                                    <Link className="text-decoration-none" onClick={() => handleShowModal('Bedsheet')}>
                                        <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733381481/products/kubzidtdikbfsn1ltydn.jpg" alt="Bedsheet" className="img-fluid product-img" />
                                        <div>
                                            <h2 className="text-darkbrown fs-5 pt-3">Bedsheet</h2>
                                        </div>
                                        <hr />
                                    </Link>
                                </Col>
                                <Col xs={12} md={3} className="mb-4">
                                    <Link className='text-decoration-none' onClick={() => handleShowModal('Carpets')}>
                                        <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733381663/products/hedbozfyokleuhpqg1ny.jpg" alt="" className="img-fluid product-img" />
                                        <div>
                                            <h2 className="text-darkbrown fs-5 pt-3">Carpets</h2>
                                        </div>
                                        <hr />
                                    </Link>
                                </Col>
                                <Col xs={12} md={3} className="mb-4">
                                    <Link className='text-decoration-none' onClick={() => handleShowModal('Carpets')}>
                                        <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733582017/products/fn7su2clib6y2qnveoqe.jpg" alt="" className="img-fluid product-img" />
                                        <div>
                                            <h2 className="text-darkbrown fs-5 pt-3">Mats</h2>
                                        </div>
                                        <hr />
                                    </Link>
                                </Col>
                                {/* <Col xs={12} md={3} className="mb-4">
                                               <Link className="text-decoration-none" onClick={() => handleShowModal('Bedsheet')}>
                                                   <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733381481/products/kubzidtdikbfsn1ltydn.jpg" alt="Bedsheet" className="img-fluid product-img" />
                                                   <div>
                                                       <h5 className="text-darkbrown fs-5 pt-3">Bedsheet</h5>
                                                   </div>
                                                   <hr />
                                               </Link>
                                           </Col> */}
                                {/* <Col xs={12} md={3} className="mb-4">
                                               <Link className='text-decoration-none' onClick={() => handleShowModal('Bed Matress')}>
                                                   <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733516437/products/ye5dk0k5skbiq62zejgl.jpg" alt="" className="img-fluid product-img" />
                                                   <div>
                                                       <h5 className="text-darkbrown fs-5 pt-3">Bed Mattress</h5>
                                                   </div>
                                                   <hr />
                                               </Link>
                                           </Col> */}
                                {/* <Col xs={12} md={3} className="mb-4">
                                               <Link className='text-decoration-none' onClick={() => handleShowModal('Bankets')}>
                                                   <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733516540/products/v51lpg7k9juufqxnq1n6.jpg" alt="" className="img-fluid product-img" />
                                                   <div>
                                                       <h5 className="text-darkbrown fs-5 pt-3">Blankets</h5>
                                                   </div>
                                                   <hr />
                                               </Link>
                                           </Col> */}
                                <Col xs={12} md={3} className="mb-4">
                                    <Link className="text-decoration-none" onClick={() => handleShowModal('Sofa Cover')}>
                                        <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732803418/products/khzpendrug25nkvmjdbm.jpg" alt="Sofa Cover" className="img-fluid product-img" />
                                        <div>
                                            <h2 className="text-darkbrown fs-5 pt-3 ">Sofa Cover</h2>
                                        </div>
                                        <hr />
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
                <div className="home-products">
                    <Container>
                        {/* Heading Section */}
                        <Row className="align-items-center">
                            <Col xs={12} md={12} className="pt-4 text-center">
                                <h1 className="text-darkbrown fs-2">Home Comforts</h1>
                                {/* <p className="text-darkbrown fw-normal fs-5">
                                                Browse our curated collections for every style.
                                            </p> */}
                            </Col>
                        </Row>

                        {/* Categories Section */}
                        <Row className="mt-4">
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Cushion Cover')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732802619/products/de6gp5xxrb5is44o5red.jpg" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h2 className="text-darkbrown fs-5 pt-3">Cushion Cover</h2>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Pillows')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732802618/products/dculvb8oqgkgt5ir27xq.jpg" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h2 className="text-darkbrown fs-5 pt-3">Pillows</h2>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Mosquito Nets')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733077022/products/hw2f1b1jufgithlsrvkl.webp" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h2 className="text-darkbrown fs-5 pt-3">Mosquito Nets</h2>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="outdoor-utility">
                    <Container>
                        {/* Heading Section */}
                        <Row className="align-items-center">
                            <Col xs={12} md={12} className="pt-4 text-center">
                                <h1 className="text-darkbrown fs-2">Outdoor and Utility Products</h1>
                                {/* <p className="text-darkbrown fw-normal fs-5">
                                                Browse our curated collections for every style.
                                            </p> */}
                            </Col>
                        </Row>

                        {/* Categories Section */}
                        <Row className="mt-4">
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Artificial Green Grass')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733076363/products/hqx0287acmt1urwc0yyw.webp" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h2 className="text-darkbrown fs-5 pt-3">Artificial Green Grass</h2>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Raincoats')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733076627/products/opu37wgkekrpqmnne0tc.webp" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h2 className="text-darkbrown fs-5 pt-3">Raincoats</h2>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Tarpaulin (Tadpatri)')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733076838/products/fvzy6jivxusoxvx3rhep.jpg" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h2 className="text-darkbrown fs-5 pt-3">Tarpaulin (Tadpatri)</h2>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedCategory}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <p>Details about {selectedCategory} go here.</p> */}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter your message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Button type="submit" className="mt-3 btn-darkbrown border-0">
                                Submit
                            </Button>
                        </Form>
                    </Modal.Body>
                    {/* <Modal.Footer> */}
                    {/* <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button> */}
                    {/* </Modal.Footer> */}
                </Modal>
            </div>
        </>
    )
}

export default Products