// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer';
import HomeFurnishings from './pages/product_category/HomeFurnishings';
import OutdoorUtility from './pages/product_category/OutdoorUtility';
import HomeProducts from './pages/product_category/HomeProducts';
import HeaderLayout from './layouts/HeaderLayout';
import Products from './pages/Products';
import PremiumCurtains from './pages/product_category/Premium Curtains/PremiumCurtains';

function App() {
  return (
    <BrowserRouter>
      <div className="sticky-top" >
        <HeaderLayout />
      </div>
      <div className="content" >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/home-furnishings" element={<HomeFurnishings />} />
          <Route path="/category/home-comforts" element={<HomeProducts />} />
          <Route path="/category/home-comforts/curtains/premium-curtains" element={<PremiumCurtains />} />
          <Route path="/category/outdoor-utility-products" element={<OutdoorUtility />} />
          <Route path="/all-products" element={<Products />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
