import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import whyvideo from '../../assests/videos/video.mp4';

const WhyChooseUs = () => {
    return (
        <>
            <div className="why-choose-us-section bg-lightestblue">
                <Container className="py-5">
                    <Row className="align-items-center">
                        {/* Text Section */}
                        <Col xs={12} md={6} lg={4} className="mb-4 mb-lg-0">
                            <h2 className="fw-medium fs-2 mb-3">Why Choose Fakhri Traders?</h2>
                            <p className="text-secondary">
                                At Fakhri Traders, we take great pride in providing the finest premium curtains and home furnishings in Rajkot. Our wide-ranging collection features everything from upholstery fabrics and roller blinds to mattresses and artificial grass, serving both individual and commercial needs. Whether you're aiming to decorate your home or equip your business with high-quality products, we guarantee exceptional value with our competitive pricing.
                                Visit us today and discover why we are the go-to name in wholesale & retail home furnishings.</p>
                        </Col>
                        {/* Video Section */}
                        <Col xs={12} md={6} lg={8}>
                            <video
                                src={whyvideo}
                                className="w-100"
                                autoPlay
                                muted
                                loop
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default WhyChooseUs;
