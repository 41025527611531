import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../../assests/css/herosection.css'
import { Link } from 'react-router-dom'

const Hero = () => {
    return (
        <div className="hero-section vh-100">
            <Container className="vh-100 d-flex flex-column justify-content-between align-items-center hero-cnt">
                {/* Top Content */}
                <Row className="d-flex flex-column justify-content-center align-items-center text-center mt-5">
                    <Col>
                        <h1 className="text-darkbrown fw-bold display-1">Fakhri Traders</h1>
                        <p className="text-darkbrown fs-4 fw-medium">
                            Your One-Stop Destination for <strong>Premium Handloom Products</strong> & <strong>Home Furnishings</strong> in Rajkot
                        </p>
                    </Col>
                </Row>

                {/* Bottom Button */}
                <Row className="d-flex justify-content-center align-items-center mb-5">
                    <Col className="text-center">
                        <Link to="/all-products">
                            <button className="btn btn-darkbrown fw-bold fs-4">Browse Our Premium Collection</button>
                        </Link>
                    </Col>
                </Row>
            </Container>

        </div>

    )
}

export default Hero