import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import categoryimage from '../../../assests/images/category1.png'
import axios from 'axios'
import { Helmet } from 'react-helmet'


const PremiumCurtains = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });


    const handleShowModal = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCategory('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            ...formData,
            category: selectedCategory, // Include selected category in the form submission
        };
        const baseUrl = process.env.REACT_APP_API_URI;
        try {
            const response = await axios.post(`${baseUrl}emails/send-category-email`, payload);
            console.log('Email sent successfully:', response.data);
            alert('Thank you! We will get back to you soon.');
            setFormData({ name: '', email: '', phone: '' }); // Clear form
            handleCloseModal(); // Close modal
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to submit the form. Please try again.');
        }
    };
    return (
        <>
            <Helmet>
                <title>Premium Curtains & Blinds | Luxury Home Furnishing Products</title>
                <meta
                    name="description"
                    content="Explore our exquisite collection of home furnishings, including elegant curtains, Roman blinds, roller blinds, and zebra blinds. Elevate your living space with sophistication and charm."
                />
            </Helmet>
            <div className="premium-curtains">
                <Container fluid>
                    {/* Heading Section */}
                    <Row className="align-items-center">
                        <Col xs={12} md={12} className="pt-4 text-center">
                            <h4 className="text-darkbrown fs-2 ">Premium Curtains</h4>
                            {/* <p className="text-darkbrown fw-normal fs-5">
                                Browse our curated collections for every style.
                            </p> */}
                        </Col>
                    </Row>

                    {/* Categories Section */}
                    <Container>
                        <Row className="mt-4">
                            <Col xs={12} md={4} className="mb-4">
                                <Link className="text-decoration-none" onClick={() => handleShowModal('Ring Curtains')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733568409/products/pxcismdgp1skzqkasjod.jpg" alt="Ring Curtains" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Ring Curtains</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className="text-decoration-none" onClick={() => handleShowModal('Arabian curtains')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733568337/products/e81srvkktzvabb2ohulg.jpg" alt="Arabian curtains" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Arabian curtains</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className="text-decoration-none" onClick={() => handleShowModal('Roman blinds')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733568616/products/vtp4u0jexghxiihd4svt.webp" alt="Roman blinds" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Roman blinds</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            {/* <Col xs={12} md={3} className="mb-4">
                                <Link className="text-decoration-none" onClick={() => handleShowModal('Sofa Cover')}>
                                    <img src={categoryimage} alt="Sofa Cover" className="img-fluid" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Sofa Cover</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col> */}
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} md={6} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Roller blinds')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733568691/products/forwvakucvcaeefpzfwz.jpg" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Roller blinds
                                        </h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={6} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Zebra Blinds')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733568801/products/e3yv6s8lkuswj6fev9bp.jpg" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Zebra Blinds</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            {/* <Col xs={12} md={3} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Bed Matress')}>
                                    <img src={categoryimage} alt="" className="img-fluid" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Bed Mattress</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Bankets')}>
                                    <img src={categoryimage} alt="" className="img-fluid" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Blankets</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col> */}
                        </Row>
                    </Container>
                </Container>
                {/* Modal for displaying category */}
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedCategory}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <p>Details about {selectedCategory} go here.</p> */}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formPhone">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="Enter your phone number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Button type="submit" className="mt-3 btn-darkbrown border-0">
                                Submit
                            </Button>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button> */}
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default PremiumCurtains